import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import PropTypes from 'prop-types'
import { useState } from 'react'

import * as S from './styles'

export const LinkedArticles = ({ articles, title, withoutArrows }) => {
  const [isLinkedArticlesOpen, setIsLinkedArticlesOpen] = useState(true)

  const handleToggleLinkedArticles = () => {
    setIsLinkedArticlesOpen(previousIsOpen => !previousIsOpen)
  }
  return (
    <S.LinkedArticlesContainer>
      <S.LinkedArticlesTitleContainer>
        <S.Chevron $isOpen={isLinkedArticlesOpen} />
        <S.LinkedArticlesTitle onClick={handleToggleLinkedArticles}>{title}</S.LinkedArticlesTitle>
        <S.LinkedArticlesDivider />
      </S.LinkedArticlesTitleContainer>

      <CollapsibleRow
        appear
        parentComponent={S.LinkedArticlesCollapsible}
        in={isLinkedArticlesOpen}
        transitionProps={{
          unmountOnExit: false,
        }}
      >
        <S.LinkedArticlesList>
          {articles.map(({ path, title }, i) => (
            <S.LinkedArticle key={i}>
              <S.LinkedArticleLink href={path}>
                <S.LinkedArticleTitle>{title}</S.LinkedArticleTitle>
                {!withoutArrows && <S.LinkedArticlePicto />}
              </S.LinkedArticleLink>
            </S.LinkedArticle>
          ))}
        </S.LinkedArticlesList>
      </CollapsibleRow>
    </S.LinkedArticlesContainer>
  )
}

LinkedArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  withoutArrows: PropTypes.bool,
}

LinkedArticles.defaultProps = {
  title: 'Sur le même sujet',
}
