import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'
import { ChevronDownBold, ChevronRightBold } from '@pretto/picto'
import styled, { css } from 'styled-components'

export const LinkedArticlesContainer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid()};
    padding-right: unset;
    padding-left: unset;
    padding-top: ${g(7)};
    padding-bottom: ${g(10)};
    background-color: ${({ theme }) => theme.colors.neutral4};
  }
`
export const LinkedArticlesCollapsible = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
  }
`
export const LinkedArticlesList = styled.ul`
  padding: ${g(4)} ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral4};
  transform: translateY(-1px);

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: ${g(4)};
    margin-top: ${g(4)};
    background-color: initial;
    padding: 0;
  }
`
export const LinkedArticlesTitleContainer = styled.div`
  padding: ${g(4)} ${g(2)} 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
    display: initial;
    padding: 0;
  }
`
export const Chevron = styled(ChevronDownBold)`
  transform: rotateZ(0deg);
  transition: transform 150ms ease-in-out;
  flex: 0 0 auto;
  margin-right: ${g(2)};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotateZ(-180deg);
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const LinkedArticlesTitle = styled(BaseTypo).attrs({ forwardedAs: 'h4', typo: 'heading32' })``
export const LinkedArticlesDivider = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  flex: 0 0 100%;
  background-color: ${({ theme }) => theme.colors.neutral3};
  margin: unset;
  margin-top: ${g(4)};
  position: relative;
  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const LinkedArticle = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};

  & + & {
    margin-top: ${g(2)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: unset;
    }
  }
`
export const LinkedArticleLink = styled(Link)`
  padding: ${g(2)};
  display: flex;
  align-items: center;
  height: 100%;
`
export const LinkedArticleTitle = styled.span`
  flex-grow: 1;
`
export const LinkedArticlePicto = styled(ChevronRightBold)`
  flex: 0 0 ${g(4)};
  margin-left: ${g(2)};
`
